<template>
  <div></div>
</template>

<script>

  // import axios from 'axios'
  import authentication from "../api/authentication";
  
  export default {
    name: 'Activation',
    data() {},
    async created() {
      console.log(this.$route.params.enableKey);
      authentication.activation(this.$route.params.enableKey).then(response => {
        this.hasResult = true;
        this.testVar = response;
        if (response.status === 200) {
          if (response.data.result === "ok") {
            localStorage.connexionMsg = "Votre profile est activé, vous pouvez vous connecter.";
            localStorage.connexionMsgType = "success";
            this.$router.push({ path: '/connexion'});
          } else {
            localStorage.connexionMsg = "Erreur lors de l'activation de votre profile";
            this.$router.push({ path: '/connexion'});
          }
        } else {
          localStorage.connexionMsg = "Erreur lors de l'activation de votrse profile";
          this.$router.push({ path: '/connexion'});
        }
      });
    }
  }
</script>

<style lang="scss">
  h1 {
    text-align: center;
    margin: 50px auto !important;
    font-weight: 600 !important;
    font-size: 30px;
  }
</style>