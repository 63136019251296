<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template home">
    <img src="/img/logo_welcome.png" alt="Emotivapp"><br>
    <img src="/img/Logo_APHP.png" alt="APHP">
    <div class="soutient text-center">
      Avec le soutien de<br>
      FRESENIUS KABI - MEDAC - MYLAN - SANDOZ
    </div>
    <div class="banner-welcome" >
      <a class="close" v-show="bannerVisible" href="#" @click="closeBanner"><font-awesome-icon icon="xmark" /></a>
      <div class="row" v-show="bannerVisible">
        <div class="col-4">
          <img src="/img/logo_home.png" alt="Emotivapp">
        </div>
        <div class="col-8">
          <a class="btn btn-emot" v-show="!isSafari && !isFirefox" @click="installApp" href="#">Ajouter EMOTIVapp<br>à votre écran d'accueil</a>
          <p class="installTxt" v-show="isSafari || isFirefox">Ajouter EMOTIVapp<br>à votre écran d'accueil<br>via le menu de votre navigateur.</p>
        </div>
      </div>
      <div class="row" v-show="!bannerVisible">
        <div class="col-12">
          <router-link class="btn btn-emot fullwidth" :to="this.nextLink">Suivant</router-link>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
 
  import authentication from "@/api/authentication";
  import backend from "@/api/backend";

  export default {
    name: 'Home',
    data() {
      return {
        deferredPrompt: null,
        isLoading: true,
        bannerVisible: true,
        installTxt: false,
        nextLink : '/accueil',
        displayMode : 'browser',
        isFirefox: false,
        isSafari: false,
      }
    },
    methods: {
      closeBanner(event) {
        if (event) {
          event.preventDefault()
        }
        this.bannerVisible = false;
      },
      async installApp() {
        if (this.deferredPrompt !== null) {
          this.deferredPrompt.prompt(); 
        } 
      }
    },
    mounted() {
      // Install App
      window.addEventListener("beforeinstallprompt", e => {
        // alert('beforeinstallprompt mounted');
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;

        console.log(`'beforeinstallprompt' event was fired.`);
      });
      window.addEventListener("appinstalled", () => {
        // alert('appinstalled mounted');
        this.deferredPrompt = null;
        this.bannerVisible = false;
      });
      
      this.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      // this.isFirefox = false;
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // this.isSafari = false;
      console.log(navigator.userAgent.toLowerCase());
    },
    
    async created () {
      const mqStandAlone = '(display-mode: standalone)';
      if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        this.displayMode = 'standalone';
        this.bannerVisible = false;
      }
      
      // Authenticate
      if (localStorage.apitoken) {
        const isAuthenticate = await authentication.isAuthenticate();
        console.log('isAuthenticate : ' + isAuthenticate);
        if (isAuthenticate) {
          const formInitDone = await backend.isFormInitDone();
          if (formInitDone != null) {
            if (formInitDone.needFormInit) {
              // this.nextLink = '/formulaire-debut';
              this.$router.push('formulaire-debut');
            } else {
              // this.nextLink = '/journal';
              this.$router.push('journal');
            }
            this.isLoading = false; 
          }
          this.isLoading = false;
        } else {
          this.nextLink = '/accueil';
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    
  }
</script>